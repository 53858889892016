.popupSearchContainerBG {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  top: 0px;
  left: 0px;
  z-index: 3 !important;
  width: 100vw;
  height: 100vh;
  gap: 0px;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupSearchContainer {
  background-color: white;

  top: 76px;
  left: 54px;

  z-index: 3;

  gap: 0px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: left;

  position: sticky;
  width: 60%;
  padding: 35px 57px;

}

.popupInwardModal {
  background-color: white;

  top: 76px;
  left: 54px;

  z-index: 3;

  gap: 0px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: left;

  position: sticky;
  width: 60%;
  padding: 35px 57px;
}

.popupWidthAdjustment {

  width: 33% !important;
  padding: 35px 57px;
}

.popupWidthAdjustmentInward {
  width: 60%;
}

.popupWidthAdjustmentCalc {
  width: 40%;
}

.popupWidthAdjustmentParamDetails {
  width: 50%;
}

/*
Author : Yash Darshankar
Date : 21/06/2024
Description: Making Popup responsive

*/
@media (max-width: 1200px) {
  .popupWidthAdjustment {
    width: 43% !important;
  }

  .popupWidthAdjustmentInward {
    width: 60% !important;
  }
}

@media (max-width: 854px) {
  .popupWidthAdjustment {
    width: 60% !important;
  }
}

@media (max-width: 600px) {
  .popupWidthAdjustment {
    width: 85% !important;
  }

  .popupWidthAdjustmentInward {
    width: 80% !important;
  }
}

@media (max-width: 420px) {
  .popupWidthAdjustment {
    width: 98% !important;
    padding: 35px 16px !important;
    margin-right: 1%;
  }

  .rowWidthAdjust {
    width: auto !important;
  }
}

.popupSearchContainer h2 {
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.94px;
  text-align: left;
  color: #616161;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.popupSearchContainer h2 i {
  cursor: pointer;
}

.popupSearchContainer h5 {
  font-family: Inter, Arial, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  color: #616161;
  margin-top: 16px;
}

.popupSearchFilterContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 10px;

  gap: 20px;
}

.popupSearchContainer select,
.popupSearchContainer input,
.popupSearchContainer textarea {
  height: 40px;
  border: 1px solid #d8d8d8;
  background: #f4f4f4;

  font-family: Inter, Arial, sans-serif;

  line-height: 14.52px;
  text-align: left;
  color: black;

  padding-left: 11px;
}

.popupSearchContainer input::placeholder,
.popupSearchContainer select,
.popupSearchContainer textarea::placeholder {
  color: #616161;
  font-size: 12px;
  font-weight: 200;
}

.popupSearchFilterContainer select {
  width: 250px;
  color: black;
  font-size: 13px;
  font-weight: 500;
}

.filterBtn {
  border: 1px solid #cc1e29;
  color: #cc1e29;
  width: 73px;
  height: 23px;
  gap: 0px;
  border-radius: 12px;

  background-color: white;
  font-family: Inter, Arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: center;
}

.popupSearchGroupContainer {
  margin-bottom: 16px;
}

.popupSearchGroupContainer>select {
  width: 100%;
}

.popupSearchColumnContainer {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}

.popupSearchColumnContainer>input,
.popupSearchColumnContainer>select {
  width: 48%;
}

.popupSearchButtonsContainer {
  margin-top: 50px;
  display: flex;
  justify-content: right;
}

.popupSearchButtons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
}

.popupSearchButtons button:first-child {
  border: 1px solid #cc1e29;
  width: 40%;
  height: 48px;
  gap: 0px;
  border-radius: 12px;
  min-width: 120px;
  max-width: 150px;

  background: #ffffff;
  color: #bb0410;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;
}

.popupSearchButtons button:last-child {
  border: 1px solid #cc1e29;
  min-width: 120px;
  max-width: 150px;

  width: 40%;
  height: 48px;
  gap: 0px;
  border-radius: 12px;

  background: #cc1e29;

  color: #ffffff;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;

  box-shadow: 0px 4px 4px 0px #00000040;
}

.formSearch {
  position: relative;
  display: flex;
  align-items: center;
}

.formSearch>i {
  position: absolute;
  left: 8px;
  color: #616161;
}

.formSearch>input {
  width: 233px;
  height: 36px;
  top: 114px;
  left: 929px;
  padding: 0px 10px 0px 40px;
  gap: 19px;
  border-radius: 8px;

  border: 1px solid #616161;

  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;

  color: #616161;

  box-shadow: 0px 4px 4px 0px #00000040;
}

.formSearch>input::placeholder {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;

  color: #616161;
}

.export_button {
  background-color: white;
  color: #616161;
  border: 1px solid #616161;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: small;
  height: fit-content;
}

.searchby_button {
  background-color: white;
  border: 1px solid #cc1e29;
  box-shadow: 0px 4px 4px 0px #00000040;

  padding: 8px 16px;
  border-radius: 8px;
  height: initial;

  color: #cc1e29;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;

  width: max-content;
}

.create_button {
  background: #cc1e29;

  color: #ffffff;
  border: 1px solid #cc1e29;
  padding: 8px 16px;

  border-radius: 8px;
  font-size: small;
  height: fit-content;
  width: 116px;

  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  gap: 8px;
}

.previous_next_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f7f8;
  padding: 20px 0px;
  gap: 8px;
}

.go-to-page {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.go-to-page>input {
  height: 36px;
  width: 36px;
  border-radius: 4px;
}

.previous_next_btns>button {
  padding: 7.93px;
  gap: 7.93px;
  border-radius: 6.34px;

  border: 0.79px solid #f1f1f1;
  background: white;
}

.previous_next_btns>button:hover {
  background-color: #bd222d;
  color: white !important;
}

.previous_next_btns>.pagination-active {
  background-color: #cc1e29;
  color: white !important;
}

.previous_next_btns>button.btn.btn-danger {
  color: #000;
}

.historyContainer {
  top: 165px;
  left: 345px;
  padding: 40px;
  gap: 6px;

  background: #ffffff;
}

.historyContainerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid #dddddd;
}

.historyContainerItemBox {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.historyContainerItemBox>p {
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #616161;
  margin-bottom: 0px !important;
}

.historyContainerItemBox>p>span {
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  font-weight: 200;
  line-height: 18px;
  text-align: left;
}

.historyContainerAvatar {
  width: 48px;
  height: 48px;
  top: 16px;
  padding: 9px;
  gap: 0px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.historyContainerAvatar>i {
  margin-bottom: 0px;
  font-size: 40px;
  color: #616161;
}

.history_title {
  background-color: #f2f7f8;
  align-items: baseline;
}

.history_title>div {
  max-width: 484px;
  margin-bottom: 28px;
}

.dropdown-item {
  display: flex;
  gap: 8px;
}

.multipleSelectHeader {
  display: inline-block;
  width: 96%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header_table {
  display: flex;
  justify-content: space-between;
  background-color: #f2f7f8;
  padding-top: 15px;
  width: 100%;
  padding-left: calc(var(--bs-gutter-x)* 0.5);
}

.header_table_box1 {
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.status-dropdown {
  display: none;
}

@media (max-width: 500px) {

  .header_table_box1,
  .header_table_box2 {
    display: none !important;
  }

  .status-dropdown {
    display: flex;
    width: 89vw;
    justify-content: center;
    align-items: center;
  }

  .header_icon {
    display: none;
  }

  .headerIconLock {
    display: none;
  }

  .profileImgBg {
    display: none;
  }

}

.customInput {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customInput>div {
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
}

.customInput input[type="checkbox"],
.customInput input[type="text"] {
  max-width: calc(50% - 5px);
  /* Adjust based on gap and number of items per row */
}

.customInput span {
  white-space: nowrap;
}

@media (max-width: 1200px) {

  .customInput>div {
    width: auto !important;
  }
}

.header_table_box2 {
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.header_table_status_item {
  width: 146px;
  height: 28px;
  padding: 10px;
  border-radius: 12px;

  background-color: white;

  font-family: Inter, Arial, sans-serif;

  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  text-align: left;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #616161;
}

.header_table_status_item2 {
  width: 113px;
  height: 28px;
  padding: 10px;
  border-radius: 12px;

  background-color: white;

  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: center;

  display: flex;
  gap: 8px;
  align-items: center;
  color: #000000;
}

.header_table_status_item>i {
  color: #616161;
}

.header_table_status_item>p,
.header_table_status_item2>p {
  margin: 0px;
}

.header_table_status_item>div {
  color: white;
  background-color: #cc1e29;
  width: 22px;
  height: 22px;
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 8px;
}

.header_table_status_item2>div {
  color: white;
  width: 14px;
  height: 14px;
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 8px;
}

.saved_sym {
  background: #adb5bd;
}

.posted_sym {
  background: #0c1d80;
}

.accepted_sym {
  background: #59df01;
}

.rejected_sym {
  background: #cc1e29;
}

.inprocess_sym {
  background: #ffb701;
}

.overdue_sym {
  background: #ff7b7b;
}

.completed_sym {
  background: #015601;
}

.tmCreated_sym {
  background: #808080;
}

/* use for table border color start*/
.saved_sym_tr>td:first-child {
  border-left: 4px solid #adb5bd;
  border-collapse: separate;
}

.posted_sym_tr>td:first-child {
  border-left: 4px solid #0c1d80;
  border-collapse: separate;
}

.accepted_sym_tr>td:first-child {
  border-left: 4px solid #59df01;
  border-collapse: separate;
}

.rejected_sym_tr>td:first-child {
  border-left: 4px solid #cc1e29;
  border-collapse: separate;
}

.inprocess_sym_tr>td:first-child {
  border-left: 4px solid #ffb701;
  border-collapse: separate;
}

.overdue_sym_tr>td:first-child {
  border-left: 4px solid #ff7b7b;
  border-collapse: separate;
}

.completed_sym_tr>td:first-child {
  border-left: 4px solid #015601;
  border-collapse: separate;
}

.tmCreated_sym_tr>td:first-child {
  border-left: 4px solid #808080;
  border-collapse: separate;
}

/*End*/
.table_item_sym>div {
  color: white;
  width: 14px;
  height: 14px;
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 8px;
}

.table_item_sym {
  display: flex;
  align-items: center;
  gap: 5px;
}

input.form-control.rounded-2.sub-element {
  width: unset !important;
  display: block !important;
}

.tab_header {
  background-color: white;
  padding: 32px;
  line-height: 36px !important;
}

.tab_header>span {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: right;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}

.tab_header_box {
  width: 60%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.tab_header_box>img {
  width: 71px;
  height: 71px;
  top: 526px;
  left: 364px;
  gap: 0px;
}

.tab_header_box>h4 {
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 24.2px;
  text-align: center;
}

.tab_footer {
  background-color: white;
  width: 100%;
  display: flex;
  padding: 32px;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 8px 8px;
}

.tab_footer>p {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
}

.tab_footer>p>span {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.52px;
  text-align: center;
}

.test_memo_selections {
  display: flex;
  justify-content: space-between;
}

.test_memo_selections>div {
  display: flex;
  align-items: center;
  width: 50%;
}

.test_memo_selections label:first-child {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}

.test_memo_group label,
.test_memo_group select {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #cc1e29;
}

.test_memo_group select {
  border: none;
}

.test_memo_group select option {
  border: none;
  color: black;
}

.form-group.my-2.test_memo_group {
  justify-content: right;
}

#sendToLab {
  border: 1px solid #cc1e29;
  background: white;
  color: #cc1e29;
}

.customInput>div {
  display: flex;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #616161;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: left;
  padding-left: 12px;
}

.modal.fade .modal-dialog {
  transition: none !important;
}

.rejectHeadingText {
  font-family: Inter, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}

.rejectRemarkTextbox {
  width: 90%;
  border: 1px solid #d8d8d8;
  background-color: white !important;
  margin-top: 39px;
}

.rejectButtonsContainer {
  width: 100%;

  display: flex;
  justify-content: center;
  margin-top: 50px;
  bottom: 78px;
  left: 0px;
}

.rejectSearchCross {
  display: flex;
  justify-content: end;
}

.rejectSearchCross>i {
  cursor: pointer;
}

i.rejectHeadingImg {
  color: #cc1e29;

  font-size: 100px !important;
}

table th {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
}

.list_icon {
  font-size: 18.3px !important;
}

.section_title.breadcum-title.card-title.h5 {
  justify-content: left;
}

.list_breadcrumb {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.breadcrumb_button {
  background: none;
  border: none;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #cc1e29;
}

.right_header_icons {
  display: flex;
  gap: 11px;
}

@media (max-width: 992px) {
  .right_header_icons {
    display: flex;
    gap: 0px;
  }
}

.branchDetailsSection {
  margin-left: calc(17vw);
}

.header_urs_label>span {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 200;
  line-height: 12.19px;
  text-align: left;
  color: #616161;
}

.header_urs_and_branch_label {
  display: flex;
  flex-direction: column;

  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.63px;
  text-align: left;
  color: #616161;
}

.checkbox-text {
  white-space: pre-line;
  display: block !important;
}

.renderList_table {
  width: 100%;
  background-color: #f2f7f8;
}

.previewHeading {
  width: 100%;
  height: 37px;
  top: 31px;
  left: 143px;

  gap: 10px;
  border-radius: 2px 0px 0px 0px;
  background: #e7e7e7;
  box-shadow: 0px 4px 4px 0px #00000040;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;

  color: #616161;
}

.renderTable td {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.52px;
  text-align: center;
  color: black;
  max-width: 100px;
}

.saveBtn {
  height: 48px;
}

.headerIconLock {
  cursor: pointer;
  margin-bottom: 4px;
}

.passwordPlolicyhint {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}

.passwordPlolicyhint p {
  text-align: left;
}

.passwordPlolicyhint li {
  list-style: none;
}

.header_list_icon {
  display: none;
}

@media screen and (max-width: 1180px) {
  .Scrollable {
    overflow-x: auto;
  }

  .renderList_table {
    overflow: auto;
  }
}

.actionOptions {
  display: flex;
  gap: 8px;
  color: #cc1e29;
  font-size: 16px;
  justify-content: center;
}

.Scrollable {
  margin-top: 0%;
}

.actionOptions i:hover {
  cursor: pointer;
}

.maximumWidth>button {
  max-width: 100%;
}

.Scrollable .card-body {
  padding-top: 0px;
}

.inputPlusMin,
.inputPlusMin:active,
.inputPlusMin:focus,
.inputPlusMin:hover {
  text-align: center;
}

.previewRenderTable tbody,
.previewRenderTable tfoot,
.previewRenderTable tr,
.previewRenderTable td {
  border-style: none !important;
}

.optionDisable {
  display: none;
}

.row {
  width: inherit !important;
}

.invisible-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
}

.rmsc {
  padding: 0px;
}

.rmsc.customMultiSelect>.dropdown-container {
  background-color: #f4f4f4;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
}

.rmsc.customMultiSelect>.dropdown-container:focus,
.rmsc.customMultiSelect>.dropdown-container:active,
.rmsc.customMultiSelect>.dropdown-container:hover {
  border: none !important;
}

.dropdown-content {
  width: max-content !important;
}

.custom_status {
  text-transform: capitalize;
}

.boldClass {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: justified;
  color: #000000;

  margin-top: 14px;
}

.sfm_count {
  display: flex;
  gap: 16px;
}

.sfm_count>div {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  text-align: left;

  color: #616161;
}

.sfm_count>div>span {
  background-color: #cc1e29;
  color: white;
  padding: 5px;
  border-radius: 25px;
}

.double_text {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.double_text>input {
  display: flex;
  width: 45%;
}

.double_text_adj .double_text>input {
  display: flex;
  width: 47%;
}

.advtestMemoTable th,
.advtestMemoTable td {
  text-align: center;
}

.advtestMemoTable .subHeading {
  margin: 0px;
  color: #bb0410;
}

.tick_icon {
  background: none;
  border: none;
}

.double_text .tick_icon {

  width: 3% !important;

}

.triple_val {
  margin-left: 8px;
}

.double_text_adj .triple_val {
  margin-left: 0px;
}

td.radio_adjust {
  width: 20%;
}

td.groupBasisTd {
  width: 20%;
}

td.groupBasisTd {
  width: 20%;
}

.assignmentGroupParameter {
  display: flex;
}

.assignmentGroupParameter>.form-group>div {
  width: 170px !important;
}


.tick_box {
  display: flex;
  justify-content: center;
}

.tick_box>.form-group {
  text-align: center;
  justify-content: center;
}

.Scrollable.addNewSetData.card {
  background-color: white;
  margin-top: 10px;
}

.Scrollable.addNewSetData.card table.table.table-white.responsive.borderless.no-wrap.align-middle.renderTable {
  --bs-table-bg: inherit;
}

.Scrollable.addNewSetData.card i.bi {
  color: #000;
}

.nonNativeButton {
  background: none;
  border: none;
  height: 36px;
  width: 36px;
  margin-top: 8px;
  position: absolute;
  top: 4px;
  right: 16px;
}

.nonNativeButton2 {
  background: none;
  border: none;
}

.pageNotFound {
  display: flex;
  justify-content: center;
  padding-top: 2%;
}

.pageNotFound>h1 {
  font-family: Inter, Arial, sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  color: #cc1e29;
}

.note {
  font-size: 12px;
}

.header {
  text-align: center;
}


table {
  border-collapse: collapse;
  width: 100%;
}

.text-right {
  text-align: right;
}

.space_between {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  width: 70%;
}

.text-center {
  text-align: center;
}

.terms_conditions {
  display: flex;
  gap: 32px;
}

.end {
  display: flex;
  justify-content: flex-end;
}

.first_td {
  border: 2px solid #8080809c;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}

.second_td {
  border: 2px solid #8080809c;
  font-size: 16px;
  font-weight: 500;
}

.header_urs_label {
  margin: 24px 10px 16px;
  margin-bottom: 5px;
}

.width-33 {
  width: 33%;
  margin-top: 3% !important;
}

.paramterTableSpecialClass {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: baseline;
}

.paramterTableSpecialClass>div {
  height: 38px;
}

.SpecialMultiSelect .dropdown-heading {
  height: 35px !important;
  width: 100% !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  flex: 1;
}

.required_mark {
  color: red;
  margin-left: 2px;
  display: contents;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderBG {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateInputSpecial {
  min-width: 142%;
  padding: 8px;
}

.sizeFieldDate {
  width: fit-content;
}

.IsBottom {
  position: absolute;
  top: -100px;
}


.sfmAdvTable {
  overflow: auto;
  width: 90%;
}

.header_table_box1_width {
  width: 54% !important;
}

.header_table_box2_width {
  width: 46% !important;
}

.autoWidthImportant {
  width: auto !important;
}

.autoWidthImportantLeft {
  display: flex;
  justify-content: right;
  margin-bottom: 16px;
  padding-right: 16px;
  position: absolute;
  bottom: 20px;
  text-align: end;
  width: 80%;
}

.moreWidth {
  min-width: max-content;
  margin-left: -100%;
}

.button_container {
  display: flex;
  justify-content: end;
}

.select__control,
.select__control:hover,
.select__control:active,
.select__control:focus {
  background-color: #f4f4f4 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  height: 0px !important;
  border: none !important;
  min-height: 0px !important;
  color: black !important;
  margin-top: -3%;
  box-shadow: unset !important;
}

.pdAdjust {
  margin-top: 10px !important;
  padding: 10px 12px !important;
}

.overlayLoaderBG {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  z-index: 3;
  margin-left: -1rem;
}

.fullScreen_overlayLoaderBG {
  top: 0px;
  left: 0px;
}

.overlayLoader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #cc1e29;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.8s linear infinite;
}

.modalHeader {
  border-radius: 0;
  padding: 10px 15px;
  text-align: center;
  color: $danger;
  background-color: $white;

  border-bottom: 3px solid #cc1e29;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}

.popupInwardButtonsContainer {
  width: 100%;

  display: flex;
  justify-content: center;
  margin-top: 32px;
  bottom: 78px;
  left: 0px;
}

.popupUpperClass {
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline;
}

.popupUpperClass label {
  width: 100% !important;
  margin-bottom: 12px !important;
}

td.groupBasisTd>.assignmentGroupParameter>.form-group {
  margin-bottom: 0.1rem !important;
  margin-top: 0.5rem !important;
}

.deleteGroupParameter {
  height: 37px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.customAdd {
  color: white !important;
  margin-right: 4px;
}

.listActionBtns {
  float: right;
  width: 80%;
}

.renderList_header_table {
  overflow: auto;
}

.doubleInputSelect {
  width: 50%;
}

.smplVerificationCondition {
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.smplVerificationCondition>input {
  display: flex;
  width: 50%;
  outline: none;
  border: none;
  border-radius: 0px !important;
}

.smplVerificationCondition>select {
  display: flex;
  width: 50%;
  outline: none;
  border: none;
  border-radius: 0px !important;
}

textarea:disabled,
select:disabled {
  background-color: #f4f4f4 !important;
}

.PhoneInputInput:focus,
.PhoneInputInput,
.PhoneInputInput:active {
  background-color: #f4f4f4;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  outline: none;
}

.backBtnOTP {
  margin-top: 9px;
  width: 56%;
}

.profileImgBg.notification-section.nav-item.show.dropdown .dropdown-menu.show.dropdown-menu-end {
  height: 300px;
  overflow-x: auto;
}

.profileImgBg.notification-section.nav-item.dropdown .dropdown-toggle::after {
  content: unset;
}

.mb-12.support-main-content.row {
  margin: 0px auto;
}

.support-page {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  height: 250px;
}

.contact-info h2 {
  color: #bb0410;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 16px;
  color: #333;
}

.support-page a {
  color: unset;
}

.fade.modal-backdrop.unauthenticate-modal.show {
  opacity: 1;
  background-color: #202020;
}

///Notification CSS
.notification_item,
.notification_item:focus,
.notification_item:active,
.notification_item:hover {
  background-color: white;
  height: 56px;
  width: 94%;
  padding: 8px;
  margin: 8px;
  gap: 12px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 9px;
  box-shadow: 0px 7px 4px 0px #00000008;
}

.notification_complete {
  border-left: 8px solid #6cbc6e;
}

.notification_rejected {
  border-left: 8px solid #cc1e29;
}

.notification_none {
  border-left: 8px solid white;
}

.profileImgBg.notification-section.nav-item.show.dropdown .dropdown-menu.show.dropdown-menu-end {
  height: auto;
  overflow-x: auto;
  max-height: 300px;
}

.load_more_btn {
  display: flex;
  justify-content: end;
  padding-right: 8px;
}

.testMemo_sfm_pdf {
  display: flex;
  justify-content: right;
  margin-bottom: 16px;
  padding-right: 16px;
  position: absolute;
  bottom: 20px;
  text-align: end;
  width: 80%;
  right: 0px;
}

.select-hint-text ul li {
  font-size: 10px;
}

.date-picker-wrapper {
  position: relative;
  display: inline-block;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  /* Adjust icon size as needed */
  color: #495057;
  /* Adjust icon color as needed */
}

.version-name {
  margin-top: 20px;
  text-align: center;
  font-size: 0.8em;
  color: #888;
}

.select-hint-text ul {
  margin-bottom: 0px;
}

.customInputDiv {
  display: flex;
  gap: 20px;
}

//// JRF PDF CSS
#generateJRFPDF,
#generateSFMPDF {
  body {
    font-family: "Inter", sans-serif;
    margin: 0;
    padding: 0;
  }

  .container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 20px;
    overflow: unset;
  }

  h1,
  h2,
  h3 {
    text-align: center;
    margin: 0;
  }

  p {
    margin: 10px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }

  .terms-conditions {
    margin-top: 20px;
    text-align: justify;
  }

  .checkbox {
    margin-right: 5px;
  }

  ul {
    margin: 0px;
  }

  li {
    padding: 3px;
  }

  h3 {
    background-color: rgb(211, 208, 208);
    font-weight: bold;
    font-size: medium;
    padding: 4px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .lab-use {
    padding-top: 10px;
  }

  .customer-signature {
    text-align: right;
    width: 100%;
    margin-top: 20px;
  }

  .note {
    font-size: 12px;
    text-align: justify;
  }

  td.header h1 {
    font-size: 25px;
    font-weight: bold;
    background: none;
  }

  td.header p {
    font-size: 14px;
    margin-bottom: 2px;
    margin-top: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 0px;
    margin-right: 0px;
    background: none;
  }

  td.header h2 {
    font-size: 22px;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 2px;
    background: none;
  }

  p.customer-signature span {
    border-bottom: 1px solid;
    padding: 0px 20px;
  }

  p.customer-signature img {
    border-bottom: 1px solid;
    height: 50px;
    width: 100px;
  }

  div.customer-signature img {
    width: 150px;
    margin-right: 70px;
  }

  #generateJRFPDF input,
  #generateSFMPDF input {
    width: auto;
  }
}

/// TEST memo PDF CSS
.TestMemoPDFContainer {
  .container {
    font-family: "Times New Roman", Times, serif;

    width: 80%;
    margin: 0 auto;
    padding: 20px;

    position: relative;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #000;
    padding: 4px;
    text-align: center;
  }

  .header {
    text-align: center;
    margin-bottom: 20px;
  }

  .header>h2 {
    font-size: 1.7rem;
  }

  .remarks {
    margin-top: 40px;
  }

  .signature {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
  }

  .footer {

    position: absolute;
    bottom: 32px;
    width: 96%;
  }

  .remarks_signature {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .docNo {
    display: flex;
    justify-content: end;
    font-size: 16px;
  }

  .docNo>h2 {
    font-size: 16px;
  }

  .logo {
    position: absolute;
    top: 40px;
    width: 16%;
  }

  .main_container {
    background: none;
    border: none;
    text-align: left;
    width: 50%;
  }

  .table_header {
    text-align: center;
  }

  .table_param {
    width: 200px;
  }

  .container {
    width: 100%;
    margin: 20px;
    max-width: 1116px;
    height: 841.89pt;
    overflow-y: auto;
  }

  .header_main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Times New Roman", Times, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.94px;
    margin-top: 50px;
  }

  .header_main>div>div {
    padding: 4px;
  }

  .pdfTable th,
  td {
    font-size: 16px !important;
    padding: 3px;
  }
}

.rolebase-buttons {
  margin: 24px 10px 16px;
  margin-bottom: 5px;
  width: auto;
  gap: 60px;
  display: flex;
  min-width: 250px;
  justify-content: center;
  align-items: center;
}

.roleBaseBtn {
  height: 32px;
  background-color: white;
  border: 1px solid #cc1e29;
  padding: 8px 16px;
  border-radius: 8px;
  color: #cc1e29;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  width: max-content;
}

.roleBaseBtn:hover {
  color: white;
  background-color: #cc1e29;
}

#generateSFMPDF .table th,
#generateSFMPDF .table td {
  padding: 0px !important;
}

#generateSFMPDF .dates_table td {
  text-align: left !important;
  padding-left: 8px;
}

.header_highlight {
  width: 180px;
}

.align-end-100 {
  display: flex;
  justify-content: end;
  width: 98%;

}

#generateSFMPDF .table {
  margin-bottom: 8px;
}

.dates_table {
  margin-bottom: 8px;
}

#generateSFMPDF td.header p {
  font-size: 14px;
  margin-bottom: 2px;
  margin-top: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 0px;
  margin-right: 0px;
  background: none;
}

td.header .h1,
#generateSFMPDF td.header h1,
#generateSFMPDF td.header .h1 {
  font-size: 25px;
  font-weight: bold;
  background: none;
  padding-bottom: 0px;
}

.additional_col {
  min-width: 124px;
}

.rolebase-buttons span {
  color: #616161;
  font-size: 14px;
}

.selectable-role .all-selectable-role {
  display: flex;
  gap: 20px;
  width: auto;
  gap: 8px;
  display: flex;
  align-items: center;
}
.rolebase-buttons .roleBaseBtn.btn{
  font-size: 10px;
}
.rolebase-buttons .roleBaseBtn.btn:disabled {
  color: #015601;
  border: 1px solid #015601;
  background: #fff;
}
td.result_col {
  width: 12%;
}

.testPreviewContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}