.form-control,
.form-control:hover,
.form-control:active,
.form-control:focus {
  background-color: #f4f4f4;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  height: 35px;
}
.jrf_header_elements,
.jrf_header_elements:hover,
.jrf_header_elements:active,
.jrf_header_elements:focus {
  background: white;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
}
.form-group label {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .form-group label {
    width: 75% !important;

    justify-content: left !important;
    margin-bottom: 8px;
  }
  .my-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
input::placeholder {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;

  font-weight: 200;
  line-height: 14.52px;
  text-align: left;
}

.form-control[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("./calender.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  border: none;
}
.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  color: #cc1e29;

  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
}

.list_body {
  background-color: #f2f7f8;
}
.searchby_container {
  display: flex;
  justify-content: right;
}
.searchby_container_input {
  width: fit-content;
  background-color: white;
  margin: 0px 16px;
  padding-bottom: 16px;
}
.nav-tabs .nav-link {
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 51px;
}
.card-title h5 {
  letter-spacing: 0em;
  color: #000000;
  width: 213px;
  height: 13px;
  top: 638px;
  left: 414px;

  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}
.submitBtn {
  background: #bb0410;
  color: white;

  margin-top: 16px;
  padding: 16px 32px;

  border-radius: 5px;
  border: none;
  margin-left: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19);

  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
}
.rejectBtn {
  background: #404040;
  color: white;

  margin-top: 16px;
  padding: 16px 32px;

  border-radius: 5px;
  border: none;
  margin-left: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19);

  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
}
.submitBtn:hover,
.submitBtn:active,
.submitBtn:focus {
  background: #bb0410;
  color: white;
  opacity: 0.7;
}
.rejectBtn:hover,
.rejectBtn:active,
.rejectBtn:focus {
  background: #404040;
  color: white;
  opacity: 0.7;
}
.saveBtn {
  background-color: white;
  color: #000000;
  margin-top: 16px;
  padding: 4px 16px;
  min-width: 120px;
  border-radius: 5px;
  border: none;
  margin-left: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19);

  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
}
.saveBtn:hover {
  background-color: white;
  color: #000000;
  opacity: 0.6;
}
.saveBtn:active {
  background-color: white;
  color: #000000;
  opacity: 0.5;
}

.cancelBtn {
  background-color: white;
  color: #000000;

  margin-top: 16px;
  padding: 16px 32px;
  width: 120px;
  border-radius: 5px;
  border: none;
  margin-left: 4px;

  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
}
.cancelBtn:hover {
  background-color: white;
  color: #000000;

  opacity: 0.6;
}
.cancelBtn:active {
  background-color: white;
  color: #000000;

  opacity: 0.5;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
.radioOption {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
}

.submit_btns {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
}
.icon-handler > i {
  position: absolute;
  top: 55%;
  right: 30px;
  transform: translateY(-50%);
  border-radius: 100px;
  padding: 10px;
}

.ag-header-cell-menu-button {
  display: none;
}
.ag-sort-indicator-container {
  display: none;
}
.ag-theme-quartz .ag-header {
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  font-weight: 1500;
  text-align: left;
  background-color: white;
  color: #000000;
}
.ag-theme-quartz .ag-header-group-cell {
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
}
.JrfReportButtonList {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  padding-right: 16px;
}
.JrfReportButtonList > div {
  display: flex;
  gap: 16px;
  margin: 0%;
  padding: 0%;
}

.list th {
  text-align: center;
  width: fit-content;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;

  padding: 8px 4px;
  background-color: #606060;

  color: #ffffff;
  cursor: pointer;

  white-space: nowrap;
  padding-left: 20px;
}
.table {
  margin: 0%;
}
.mainRenderList td{
  overflow: hidden;
}
.mainRenderList .exclude-click {
  overflow: visible !important;
}

.table_header_icon {
  width: 24px;
  height: 23px;
  padding: 9px 7px 9px 7px;
  gap: 10px;
}
.list th:first-child {
  border-radius: 12px 0px 0px 0px;
}
.list th:last-child {
  border-radius: 0px 12px 0px 0px;
}
.list {
  border-collapse: separate;
  border-spacing: 0px 8px;
  font-size: 0.9em;
  font-family: Inter, Arial, sans-serif;
  min-width: 400px;
  border-radius: 4px;
  background-color: #f2f7f8;
}
.list thead,
.list thead tr:first-child {
  border-radius: 12px 12px 0px 0px !important;
}

.list td {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;

  max-width: 240px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 12px 15px;
}
.list_title {
  background-color: #f2f7f8;
  align-items: baseline;
  display: flex;
  gap: 22px;
}
@media (max-width: 800px) {
  .list_title {
    background-color: #f2f7f8;
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
}

.list tbody tr {
  height: 56px;
  border-collapse: separate;
  border-spacing: 10px;
  box-shadow: 0px 4px 4px 0px #0000001f;
}
.completed_status_tr > td:first-child {
  border-left: 4px solid #cc1e29;
  border-collapse: separate;
}
.list tr {
  text-align: left;
}
.actionBtns {
  display: flex;
  gap: 8px;
}
.radioOptions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.listform {
  display: flex;
  align-items: center;
  width: auto;
}
.csv-icon {
  margin-bottom: 0%;
}
#search-icon {
  padding: 0.1rem 0.5rem;
}
.form-control {
  appearance: auto !important;
}
.popup_body {
  max-height: "calc(100vh - 210px)";
  overflow-x: "scroll";
}
.modal-dialog-scrollable .modal-content {
  width: 95vw;
}
.modal-header {
  background: $danger;
  color: #ffffff;
  padding: 8px;
  padding-left: 32px;
  padding-top: 16px;
  padding-right: 32px;
}
.modal-title {
  font-size: 1rem;
}
.modal-footer {
  padding: 8px;
  padding-right: 32px;
}
.renderTable > :not(caption) > * > * {
  padding: 0.5rem 0;
}
.List-Btn {
  background-color: #bb0410;
  color: white;
  border-radius: 50px;
  border: none;
}

.List-Btn:focus {
  background: #bb0410;
  color: white;
  opacity: 0.7;
}
.List-Btn:hover {
  background: #bb0410;
  color: white;
  opacity: 0.6;
}
.List-Btn:active {
  background: #bb0410;
  color: white;
  opacity: 0.5;
}
.accordion-item {
  margin-top: 16px;
}
.dropdown_options {
  background-color: blue;
  padding: 0%;
  margin: 0%;
  width: fit-content;
}
.select-entity {
  display: flex;
  align-items: center;
}
.List-Btn-remove {
  border: none;
  background-color: white;
}
.List-Btn-disabled {
  border: none;
  background-color: grey;
  color: white;
  border-radius: 50px;
}
.button-container {
  display: flex;
  gap: 16px;
  margin-left: 4px;
}
.dropdown-toggle {
  background-color: #f4f4f4;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #1e2a35;
  max-width: 112px;
  width: 100%;
  overflow: hidden;
}
.labelValue {
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: grey;
}
.InputBtn-Active {
  background-color: "red";
}

.table-container {
  width: 100%;
  border-collapse: collapse;
  margin-top: 36px;
}

.table-container th,
.table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-container th {
  background-color: #f2f2f2;
}
.iconBtn {
  padding: 8px;
  color: #bb0410;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #cc1e29;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  width: 126px;
}
.iconBtn1 {
  padding: 0px 16px;
  background-color: #cc1e29;
  border-radius: 6px 0px 0px 6px;
  color: white;
  border: none;
  height: 32px;
}

.iconBtn2 {
  padding: 0px 16px;
  background-color: #cc1e29;

  border-radius: 0px 6px 6px 0px;
  color: white;
  border: none;
  height: 32px;
}
.actionColumn {
  text-align: left;
  padding-left: 20px;
  position: relative;
}
.maxWidth{
  width: max-content;
}
.actionColumn > i {
  cursor: pointer;
}
.popupOptions {
  position: absolute;
  background-color: white;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 1;
  width: 131px;
  left: -108px;
  top: 24px;
}
.popupOptions > div {
  padding: 4px 16px;
  font-family: Inter, Arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #616161;
}
.popupOptions > div i {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}
.popupOptions > div:hover,
.popupOptions > div i:hover,
.popupOptions > div > button:hover {
  color: #cc1e29 !important;
}
.actionColumn > i,
.actionColumn > button {
  margin: 4px;
}
.container {
  margin: 20px;
  max-width: 910px;
  overflow-y: scroll;
}
.container input {
  width: 70px;
}

.select-group,
.add-sample-btn {
  margin-left: 10px;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th,
.data-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.data-table th {
  background-color: #f2f2f2;
}

.result_column {
  text-align: center;
}
.accordion-button:not(.collapsed) {
  background-color: #cc1e29;
  color: white;
}
.accordion-collapse.collapse.show > .accordion-body > a.active.nav-link {
  color: #616161;
  font-weight: bold;
  background-color: white !important;
  border-right: 6px solid #cc1e29;
}

.accordion-collapse.collapse.show > .accordion-body > a.nav-link i {
  color: #cc1e29;
  width: 26.25px;
  height: 27.19px;
  top: 2.81px;
  left: 1.88px;
  gap: 0px;
  margin-left: 10px;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.adv_table_thead th {
  border: 1.5px solid black;
}

.adv_table_thead td {
  padding: 0rem 0.5rem;
}
.adv_table_thead div {
  padding: 0 !important;
  margin: 0 !important;
}
.btn-number,
.btn-number:hover,
.btn-number:active,
.btn-number:focus {
  background-color: #bb0410;
  color: white;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.plus-min-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_icon {
  color: #cc1e29;
  display: block;
  cursor: pointer;
  font-size: 25px !important;
}
.profileImg {
  border-radius: 40px;

  width: 44px;
  height: 44px;

  background-color: white;
}
.profileImgBg {
  background-color: white !important;
}
.header_label,
.header_label:hover,
.header_label:active,
.header_label:focus {
  height: 28px;

  padding: 9px 14px 9px 14px;

  border-radius: 0px 12px 0px 12px;

  background: #cc1e29;

  font-family: Inter, Arial, sans-serif;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14.52px !important;
  text-align: left !important;

  color: #f4f4f4;
}
.tcrcLogo {
  background-color: #f7f7f7;
  position: fixed;
  width: 17vw;
  top: 0px;
  height: calc($navHeight + 7px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-fluid {
  padding: 0% !important;
}
.navbar-brand {
  margin-right: 0%;
}

#navbarDropdown {
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  gap: 4px;
}
.card-title-icon {
  color: #616161;
}
.nav-sidebar-title {
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.nav-sidebar-subtitle {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}
.trash_btn,
.trash_btn:hover,
.trash_btn:focus,
.trash_btn:active {
  border: none;
  background: none;
  color: #cc1e29;
}

input[type="date"]::placeholder {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 14.52px;
  text-align: left;
}

select.default-placeholder option:first-child {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 14.52px;
  text-align: left;
}

.jrf_container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  flex-wrap: wrap;
}
.jrf_container_btns {
  display: flex;
  justify-content: right;
  gap: 16px;
}
.jrf_container_btns > select {
  border-radius: 1px;
  color: #616161;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23CC1E29" className="bi bi-caret-down-square-fill" viewBox="0 0 16 16"><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4 4a.5.5 0 0 0-.374.832l4 4.5a.5.5 0 0 0 .748 0l4-4.5A.5.5 0 0 0 12 6z"/></svg>');
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.jrf_input,
.jrf_select {
  width: 128px;
  height: 22px;
  border: 1px solid #e9e9e9;
  background-color: white;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 1px;
}

.section_title {
  background-color: #f2f7f8;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding-top: 23px;
  padding-bottom: 30px;
}
.section_card {
  margin-top: 0px;
  width: 100%;
}
.section_card_body {
  margin-top: 0px;
  padding-top: 0px;
}
.main_form {
  width: 80vw;
  --bs-gutter-x: 1.85rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  padding-left: 32px;
}
.submitBtn_container {
  display: flex;
  justify-content: right;
}
.errorMsg {
  padding-left: 10px;
  padding-top: 4px;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 12.52px;
  letter-spacing: 0em;
  text-align: left;
  color: #cc1e29;
  display: flex;
  align-items: center;
}
.horizontalLine {
  background-color: #e9e9e9;
  height: 1px;
  margin: 15px 0px;
}
.section_heading {
  font-family: Inter, Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 19.36px !important;
  text-align: left !important;
  color: #cc1e29 !important;
  width: max-content !important;
}
.section_heading2 {
  font-family: Inter, Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 18px !important;
  text-align: left !important;
  color: #616161 !important;
  width: 100% !important;
  margin-bottom: 16px;
  margin-top: 8px;
}
.section_sub_heading {
  font-family: Inter, Arial, sans-serif;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.94px !important;
  color: #616161 !important;
  width: 100%;
  margin-left: 37px;
}
