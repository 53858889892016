.login_container {
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  align-items: center;
}

.login_page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  height: 100vh;
  background-color: white;
}

.login_title {
  display: flex;
  justify-content: left;

  color: #616161;
  width: 100%;
  padding-left: 20%;

  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36.57px;
  text-align: left;
}
.login_container > label,
.login_container > input,
.login_container > button,
.password_options {
  width: 60%;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}
.login_container_label {
  color: #4d5959;
  margin-bottom: 4px;
  margin-top: 26px;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 550;
  width: 64%;
}
.login_container > input,
.login_container > input:hover,
.login_container > input:focus,
.login_container > input:active {
  height: 53px;
  border: 1px solid #cc1e29 !important;
  background: none;
  border-radius: 8px;
  padding: 0px 16px;
}
.password_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}
.password_options > span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.password_options > span label {
  color: #000000;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.password_options > a {
  color: #cc1e29;
  text-decoration: none;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
}
.login_container > button {
  background-color: #cc1e29;
  border-radius: 40px;
  color: #ffffff;
  height: 60px;
  border: none;
  margin-top: 32px;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
}
.input_container {
  display: flex;
  background-color: white;
  width: 60%;
  gap: 8px;
  height: 55px;
  border: 1px solid #7c7c7c !important;
  border-radius: 8px;

  position: relative;
  min-width: 260px;
}
.input_container_changePassword {
  width: 100%;

  border: 1px solid #d8d8d8 !important;
}
.input_container > img {
  height: 36px;
  width: 36px;
  margin-top: 8px;
  position: absolute;
  top: 0px;
  left: 24px;
}
.input_container i {
  color: #7c7c7c;
}
@media (max-width: 715px) {
  .login_page > img {
    display: none;
  }
  .login_container {
    width: 100%;
  }
}
.input_container > input {
  border: none !important;
  width: 100%;
  padding-left: 81px;
  border-radius: 8px;
  min-height: 53px;
}
.input_container > input:hover,
.input_container > input:active,
.input_container > input:focus {
  border: 2px solid blue;
}

.my-2 {
  display: flex;
}
.forgot_password_title {
  line-height: 58px;
  display: flex;
  justify-content: center;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 500 !important;
  margin-top: 20%;
}

.forgot_password_sub_title {
  color: #686464;

  font-size: 14px;
  font-weight: 550;
  display: flex;
  justify-content: left;
  width: 100%;
  padding-left: 20%;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;

  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.forgot_password_label {
  line-height: 44px;
  display: flex;
  justify-content: left;
  margin-left: 5%;
  color: #4d5959;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 550;
}
.forgot_password_radio {
  background-color: #f1f1f1;
  width: 60%;
  border-radius: 8px;
  padding: 11px 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #686464;
  font-size: 12px;
  line-height: 18px;
}
.forgot_password_otp {
  display: flex;
  justify-content: left;
  gap: 16px;

  width: 100%;

  padding-left: 20%;
}
.forgot_password_otp > input {
  width: 63px;
  height: 57px;
  text-align: center;
  border: 0.75px solid #9badca;
}
.forgot_password_otp_timer {
  color: #cc1e29;
  margin-top: 16px;
}
.forgot_password_resend {
  color: #686464;
  margin-top: 16px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
}
.forgot_password_resend_link,.forgot_password_resend a {
  color: #cc1e29;
  margin-top: 4px;
  text-decoration: none;
  background: none;
  border: none;
}
.forgot_password_successfully {
  display: flex;
  justify-content: center;

  color: #686464;
  margin: 32px 0px;

  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 700 !important;
  line-height: 39.81px;
  text-align: center;
}
.forgot_password_sub_title_successfully {
  color: #686464;
  font-size: 12px;

  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 18.37px;
  letter-spacing: 0.11484018713235855px;
  text-align: center;
}
.login_bg_image {
  position: absolute;
  right: 0%;
  height: 94%;
}
.carousel-item-start,
.carousel-item-prev,
.carousel-item-end {
  position: absolute !important;
  right: 0% !important;
  height: 94% !important;
}
.carousel-item > img {
  height: 95vh;
  width: 40vw;
}
.carousel-item {
  width: 40vw;
}
@media (max-width: 600px) {
  .carousel-item > img {
    display: none !important;
  }
}

.carousel-indicators > button {
  width: 16.76px !important;
  height: 16.76px !important;
  background: #b3b3b3;
  border-radius: 120px !important;
  box-sizing: inherit !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  padding: 7.62px !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-reveal {
  display: none;
}
