.sidebarArea {
  flex-shrink: 0;
  width: 17vw;
  background-color: white !important;
  color: #616161;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  position: fixed;
  top: $navHeight;
  height: 100vh;
  z-index: 1;

  .subMenuIcon {
    float: inline-end;
  }

  .sidenav-bg:hover {
    background-color: $sidebar-menu-bg;
    color: #616161 !important;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }

  .sidenav-bg .active {
    background-color: $danger;
    color: $light !important;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }

  .sidenav-bg {
    background-color: white;
    color: #616161;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }

  .sidebarNav .sidenav-bg a {
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    color: #616161;

    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }
  .accordion-body a {
    height: 65px;
  }

  .accordion-header > button {
    height: 65px;
  }

  .accordion-item {
    border-radius: 0px;
  }

  .sidenav-bg .active i {
    color: $light !important;
  }

  .sidebarNav .sidenav-bg .sub_menu a .sub_menu_icon {
    color: #616161 !important;
  }

  .sidenav-bg .sub_menu .active,
  .sidenav-bg .sub_menu .active .sub_menu_icon {
    color: $danger !important;
  }

  .sidebarNav .sidenav-bg a .subMenuIcon {
    position: relative;
  }
  .sidebarNav .sidenav-bg a span {
    bottom: 5px;
    position: relative;
  }
}

.sub_menu {
  background-color: white !important;
}

ul.sub_menu li a.active {
  border-right: 10px solid $danger;
  background-color: white !important;
  color: #616161 !important;
}

.sub_menu_icon {
  margin-left: 25px;
}
.sidebar_icon {
  font-size: 30px;
}
@media (max-width: 1480px) {
  li.sidenav-bg.nav-item a {
    padding-right: 8px !important;
  }
  li.sidenav-bg.nav-item .ms-3 {
    margin-left: 10px !important;
  }
  li.sidenav-bg.nav-item .sub_menu_icon {
    margin-left: 30px;
  }
}
/*
Use : This is Replce the default padding py-2 of sidebar item
Author : Yash Darshankar
Date:18-06-2024
*/
.sidebarItem {
  padding: 0.7rem 0rem !important;
}
