.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;

  max-width: 82vw;

  overflow-x: hidden;
  overflow-y: auto;

  margin-left: 18%;
  margin-top: $navHeight;
  height: calc(100vh - $navHeight);
}
@media (max-width: 1416px) {
  .contentArea {
    flex-grow: 1;

    max-width: 100vw;

    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 0%;
  }
  .sidebarArea {
    width: 250px;
  }


  .tcrcLogo {
    width: 250px !important;
  }
  .header_list_icon {
    display: block !important;
    padding-right: 12px;
  }
  .branchDetailsSection {
    margin: 0px !important;
  }
  .tcrcLogoHeader{
    display: none !important;
  }
}
.header_urs_label {
  margin-left: 12px !important;
}

@media (max-width: 600px) {
  .contentArea {
    flex-grow: 1;

    max-width: 100vw;

    overflow-x: hidden;
    overflow-y: auto;
  }


  .jrf_container_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
  }
}
@media (max-width: 992px) {
  .tcrcLogo {
    width: 250px !important;
  }
  .header_urs_label {
    margin-left: 12px !important;
  }
  .header_label {
    margin-left: 0px !important;
  }
}
.card {
  margin-top: 24px;
}
.navbar-expand .navbar-nav {
  background-color: white;
}
.navbar {
  position: fixed;
  width: 100%;
  height: $navHeight;
  top: 0;
}

.web-header:after {
  background-color: $light;
  display: block;
  clear: both;
}
.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
.renderTable > thead > tr:last-child {
  border-bottom: 2px solid;
  padding: 5rem;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.nav-tabs .nav-link {
  border-radius: 0;
  background-color: white;
  color: #000000;

  font-weight: 500;
  padding: 10px 15px;
  text-align: center;

  &.active {
    color: $danger;
    background-color: $white;

    border-bottom: 3px solid #cc1e29;
  }
}
.text-secondary {
  background-color: #616161;

  --bs-text-opacity: #616161;
}
